// import React, { useState, useEffect } from 'react';
// import Header from '../../components/header/Header';
// import Footer from '../../components/footer/Footer';
// import './checkoutForm.scss';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';
// import { ApiBaseUrl } from '../../../../BaseUrl';
// import { toast } from 'react-toastify';
// import { useNavigate } from 'react-router-dom';

// export default function CheckoutForm() {
//     const navigate = useNavigate();
//     const accessToken = localStorage.getItem("ACCESS_TOKEN");
//     const { order_id, parent_id } = useParams();
//     const HOST_ID = localStorage.getItem("PARENT_ID");

//     const [formData, setFormData] = useState({
//         pet_check_out_person: '',
//         any_additional_information: '',
//         discount_amount: '',
//         pet_check_out_date: '', // Initially empty
//         pet_check_out_time: '', // Initially empty
//         pet_check_out_file: null
//     });

//     // Effect to set the current date and time
//     useEffect(() => {
//         const currentDate = new Date();
//         const formattedDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD format

//         // Get the current time in 12-hour format
//         const hours = currentDate.getHours();
//         const minutes = currentDate.getMinutes();
//         const period = hours >= 12 ? 'PM' : 'AM';
//         const formattedTime = `${(hours % 12 || 12).toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`; // 12-hour format

//         setFormData((prevData) => ({
//             ...prevData,
//             pet_check_out_date: formattedDate,
//             pet_check_out_time: formattedTime
//         }));
//     }, []);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         setFormData({
//             ...formData,
//             [name]: value
//         });
//     };

//     const handleImageChange = (e) => {
//         setFormData({
//             ...formData,
//             pet_check_out_file: e.target.files[0]
//         });
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const data = new FormData();

//         data.append('pet_check_out_person', formData.pet_check_out_person);
//         data.append('any_additional_information', formData.any_additional_information);
//         data.append('pet_check_out_date', formData.pet_check_out_date);
//         data.append('discount_amount', formData.discount_amount);
//         data.append('pet_check_out_time', formData.pet_check_out_time);
//         if (formData.pet_check_out_file) {
//             data.append('pet_check_out_file', formData.pet_check_out_file);
//         }
//         data.append('order_id', order_id);
//         const apiUrl = `${ApiBaseUrl}/parent/${parent_id}/${HOST_ID}/final_checkout_order`;

//         try {
//             const response = await axios.post(apiUrl, data, {
//                 headers: {
//                     'Content-Type': 'multipart/form-data',
//                     Authorization: `Bearer ${accessToken}`,
//                 },
//             });
//             if (response.status === 200) {
//                 toast.success(response.data.message);
//                   setTimeout(() => {
//                    navigate("/host/my-bookings");
//                  }, 3000);
//             } else {
//                 toast.error('Something went wrong!');
//             }
//         } catch (error) {
//             console.error('API error:', error);
//             toast.error('Something went wrong!');
//         }
//     };

//     return (
//         <>
//             <Header />
//             <section style={{ paddingTop: "100px", paddingBottom: "200px" }}>
//                 <div className="checkout">
//                     <div className="checkout-heading">
//                         <h4>Booking Checkout</h4>
//                     </div>
//                     <form className="checkout-form" onSubmit={handleSubmit}>
//                         <div className="form-group">
//                             <label htmlFor="pet_check_out_person">Checkout Person (Optional)</label>
//                             <input
//                                 type="text"
//                                 id="pet_check_out_person"
//                                 name="pet_check_out_person"
//                                 value={formData.pet_check_out_person}
//                                 onChange={handleChange}
//                                 placeholder="Enter the person's name"
//                             />
//                         </div>
//                         <div className="form-group">
//                             <label htmlFor="any_additional_information">Additional Info (Optional)</label>
//                             <textarea
//                                 id="any_additional_information"
//                                 name="any_additional_information"
//                                 value={formData.any_additional_information}
//                                 onChange={handleChange}
//                                 rows="4"
//                             />
//                         </div>
//                         <div className="form-group">
//                             <label htmlFor="discount_amount">Discount Amount (Optional)</label>
//                             <input
//                                 type="number"
//                                 id="discount_amount"
//                                 name="discount_amount"
//                                 value={formData.discount_amount}
//                                 onChange={handleChange}
//                             />
//                         </div>
//                         <div className="form-group">
//                             <label htmlFor="pet_check_out_date">Checkout Date</label>
//                             <input
//                                 type="date"
//                                 id="pet_check_out_date"
//                                 name="pet_check_out_date"
//                                 value={formData.pet_check_out_date}
//                                 onChange={handleChange}
//                                 required
//                                 readOnly // Make it unchangeable
//                             />
//                         </div>
//                         <div className="form-group">
//                             <label htmlFor="pet_check_out_time">Checkout Time</label>
//                             <input
//                                 type="text"
//                                 id="pet_check_out_time"
//                                 name="pet_check_out_time"
//                                 value={formData.pet_check_out_time}
//                                 onChange={handleChange}
//                                 required
//                                 readOnly
//                             />

//                         </div>
//                         <div className="form-group">
//                             <label htmlFor="pet_check_out_file">Checkout Image</label>
//                             <input
//                                 type="file"
//                                 id="pet_check_out_file"
//                                 name="pet_check_out_file"
//                                 accept="image/*"
//                                 onChange={handleImageChange}
//                                 required
//                                 />
//                         </div>
//                         <div className="form-group">
//                             <button type="submit" className="save-btn">Save</button>
//                         </div>
//                     </form>
//                 </div>
//             </section>
//             <Footer />
//         </>
//     );
// }

import React, { useState, useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./checkoutForm.scss";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ApiBaseUrl } from "../../../../BaseUrl";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

// Utility function for getting the current time in 12-hour format
const getCurrentTimeIn12HourFormat = () => {
  const today = new Date();
  let hours = today.getHours();
  const minutes = today.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert to 12-hour format
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${formattedMinutes}:00 ${ampm}`;
};

// Utility function for getting the current date in YYYY-MM-DD format
const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split("T")[0];
};

export default function CheckoutForm() {
  const navigate = useNavigate();
  const accessToken = localStorage.getItem("ACCESS_TOKEN");
  const { order_id, parent_id } = useParams();
  const HOST_ID = localStorage.getItem("PARENT_ID");

  const [formData, setFormData] = useState({
    pet_check_out_person: "",
    any_additional_information: "",
    discount_amount: "",
    pet_check_out_date: "", // Initially empty
    pet_check_out_time: "", // Initially empty
    pet_check_out_file: null,
  });

  // Use the utility functions to set the current date and time
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      pet_check_out_date: getCurrentDate(),
      pet_check_out_time: getCurrentTimeIn12HourFormat(),
    }));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleImageChange = (e) => {
    setFormData({
      ...formData,
      pet_check_out_file: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = new FormData();

    data.append("pet_check_out_person", formData.pet_check_out_person);
    data.append(
      "any_additional_information",
      formData.any_additional_information
    );
    data.append("pet_check_out_date", formData.pet_check_out_date);
    data.append("discount_amount", formData.discount_amount || 0);
    data.append("pet_check_out_time", formData.pet_check_out_time);
    if (formData.pet_check_out_file) {
      data.append("pet_check_out_file", formData.pet_check_out_file);
    }
    data.append("order_id", order_id);
    const apiUrl = `${ApiBaseUrl}/parent/${parent_id}/${HOST_ID}/final_checkout_order`;

    try {
      const response = await axios.post(apiUrl, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${accessToken}`,
        },
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        setTimeout(() => {
          navigate("/host/my-bookings");
        }, 3000);
      } else {
        toast.error("Something went wrong!");
      }
    } catch (error) {
      console.error("API error:", error);
      toast.error("Something went wrong!");
    }
  };

  return (
    <>
      <Header />
      <section style={{ paddingTop: "100px", paddingBottom: "200px" }}>
        <div className="checkout">
          <div className="checkout-heading">
            <h4>Booking Checkout</h4>
          </div>
          <form className="checkout-form" onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="pet_check_out_person">
                Checkout Person (Optional)
              </label>
              <input
                type="text"
                id="pet_check_out_person"
                name="pet_check_out_person"
                value={formData.pet_check_out_person}
                onChange={handleChange}
                placeholder="Enter the person's name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="any_additional_information">
                Additional Info *
              </label>
              <textarea
                id="any_additional_information"
                name="any_additional_information"
                value={formData.any_additional_information}
                onChange={handleChange}
                rows="4"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="discount_amount">
                Discount Amount (Optional)
              </label>
              <input
                type="number"
                id="discount_amount"
                name="discount_amount"
                value={formData.discount_amount}
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="pet_check_out_date">Checkout Date</label>
              <input
                type="date"
                id="pet_check_out_date"
                name="pet_check_out_date"
                value={formData.pet_check_out_date}
                onChange={handleChange}
                required
                readOnly // Make it unchangeable
              />
            </div>
            <div className="form-group">
              <label htmlFor="pet_check_out_time">Checkout Time</label>
              <input
                type="text"
                id="pet_check_out_time"
                name="pet_check_out_time"
                value={formData.pet_check_out_time}
                onChange={handleChange}
                required
                readOnly
              />
            </div>
            <div className="form-group">
              <label htmlFor="pet_check_out_file">Checkout Image *</label>
              <input
                type="file"
                id="pet_check_out_file"
                name="pet_check_out_file"
                accept="image/*"
                onChange={handleImageChange}
                required
              />
            </div>
            <div className="form-group">
              <button type="submit" className="save-btn">
                Save
              </button>
            </div>
          </form>
        </div>
      </section>
      <Footer />
    </>
  );
}
