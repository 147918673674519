import React, { useEffect, useState } from "react";
import "./hostBookings.scss";
import axios from "axios";
import { ApiBaseUrl } from "../../../../BaseUrl";
import { Link } from "react-router-dom";

const HostBookings = () => {
  const [myBooking, setMybooking] = useState([]);
  const PARENT_ID = localStorage.getItem("PARENT_ID");

  useEffect(() => {
    mybookingapi();
  }, []);

  const mybookingapi = () => {
    const accessToken = localStorage.getItem("ACCESS_TOKEN");
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    axios
      .get(`${ApiBaseUrl}/host/${PARENT_ID}/mybookings`, config)
      .then((response) => {
        setMybooking(response.data.orders);
      });
  };

  // Function to check if the date is today or a future date
  const isTodayOrFuture = (date) => {
    const currentDate = new Date();
    const bookingDate = new Date(date);
    return bookingDate >= currentDate.setHours(0, 0, 0, 0); // Set current date time to 00:00:00 for comparison
  };

  return (
    <>
      <section>
        <div className="mybooking_host">
          <div className="mybooking-heading">
            <h4>My Bookings</h4>
          </div>
        </div>
        <div className="checkout_flex2_host">
          <div className="best-host-data">
            {myBooking.length === 0 ? (
              <p>No Bookings to Show</p>
            ) : myBooking && Array.isArray(myBooking) ? (
              myBooking.map((item) => {
                return (
                  <div className="best-host-inner" key={item.id}>
                    <div className="best_host_flex1">
                      <img
                        src={`${ApiBaseUrl}${item.parent.profile_pic}`}
                        alt=""
                      />
                    </div>
                    <div className="best_host_flex2">
                      <h6>{item?.parent?.name}</h6>
                      <p>{item?.parent?.mobile}</p>
                      <p>
                        {item?.parent?.address1}
                        {item?.parent?.city}
                      </p>
                      <div className="timings">
                        <div className="start">
                          <p>
                            Pickup Date: <span>{item.start_date}</span>
                          </p>
                          <p>
                            Pickup Time: <span>{item.check_in_time}</span>
                          </p>
                        </div>
                        <div className="end">
                          <p>
                            Drop-off Date: <span>{item.end_date}</span>
                          </p>
                          <p>
                            Drop-off Time: <span>{item.check_out_time}</span>
                          </p>
                        </div>
                      </div>
                      <div className="view_order_button">
                        <Link
                          className="order_btn"
                          to={`/host-final-order-summary/${item.id}`}
                        >
                          View Order
                        </Link>

                        {/* Check-In button condition */}
                        {item.pet_check_in_date === null &&
                        isTodayOrFuture(item.start_date) ? (
                          <Link
                            className="pay_btn"
                            to={`/order-checkin/${item.id}/${item.parent.id}`}
                          >
                            Check-In
                          </Link>
                        ) : null}

                        {/* Check-Out button condition */}
                        {item.pet_check_out_time === null &&
                        item.pet_check_in_date !== null &&
                        isTodayOrFuture(item.end_date) ? (
                          <Link
                            className="pay_btn"
                            to={`/order-checkout/${item.id}/${item.parent.id}`}
                          >
                            Check-Out
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <h1></h1>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default HostBookings;
